import {
  accountsRetrieve,
  accountsStatsRetrieve,
  getAccountsRetrieveQueryKey,
  getAccountsStatsRetrieveQueryKey,
} from '@/client/backend/api/accounts/accounts'
import { getSitesListQueryKey, sitesList } from '@/client/backend/api/sites/sites'
import { getUsersMeRetrieveQueryKey, usersMeRetrieve } from '@/client/backend/api/users/users'
import { SitesListParams } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { FeatureType } from '@/types/feature-type'
import { hasAccesTo } from '@/lib/permission'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import DashboardBiodiversity from '@/components/beeoimpact/dashboard-biodiversity'
import DashboardPesticides from '@/components/beeoimpact/dashboard-pesticides'
import BreadcrumbComponent from '@/components/breadcrumb-component'
import Loader from '@/components/loader'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'

const DashboardImpactPage = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const page = searchParams.get('page')
  const pageSize = searchParams.get('page_size')
  const ordering = searchParams.get('ordering')
  const search = searchParams.get('search')

  const parseNumberParam = (param: string | null, defaultValue: number) => {
    const parsed = parseInt(param ?? '', 10)
    return isNaN(parsed) ? defaultValue : parsed
  }

  const params: SitesListParams = {
    page: search ? 1 : parseNumberParam(page, 1),
    page_size: parseNumberParam(pageSize, 10),
    ordering: ordering || '-stats_biodiversity__nb_total_threatened_species',
    ...(search ? { search } : {}),
  }

  const { data: user, isLoading: isLoadingUser } = useQuery({
    queryKey: [getUsersMeRetrieveQueryKey()],
    queryFn: () => usersMeRetrieve(),
  })

  const accountId = user?.account_id ?? 0
  const userFeatures = user?.features ?? []

  const { data: paginatedSites, isLoading: isLoadingSites } = useQuery({
    queryKey: [getSitesListQueryKey(params)],
    queryFn: () => sitesList(params),
  })

  const { data: stats, isLoading: isLoadingStats } = useQuery({
    queryKey: [getAccountsStatsRetrieveQueryKey(accountId)],
    queryFn: () => accountsStatsRetrieve(accountId),
    enabled: !!user,
  })

  const { data: account, isLoading: isLoadingAccount } = useQuery({
    queryKey: [getAccountsRetrieveQueryKey(accountId)],
    queryFn: () => accountsRetrieve(accountId),
    enabled: !!user,
  })

  if (isLoadingUser) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Loader />
      </div>
    )
  }

  const hasBiodiversityAccess = hasAccesTo(userFeatures, FeatureType.BIODIVERSITY)
  const hasPesticidesAccess = hasAccesTo(userFeatures, FeatureType.PESTICIDES)
  const fullAccess = hasBiodiversityAccess && hasPesticidesAccess

  const renderDashboard = () => {
    if (fullAccess) {
      return (
        <Tabs defaultValue="biodiversity">
          <TabsList className="mb-4">
            <TabsTrigger value="biodiversity">{t(i18nKeys.beeoimpact.dashboard.trigger.biodiv)}</TabsTrigger>
            <TabsTrigger value="pesticides">{t(i18nKeys.beeoimpact.dashboard.trigger.pesticides)}</TabsTrigger>
          </TabsList>
          <TabsContent value="biodiversity">
            <DashboardBiodiversity
              account={account}
              stats={stats}
              isLoadingStats={isLoadingStats}
              isLoadingAccount={isLoadingAccount}
            />
          </TabsContent>
          <TabsContent value="pesticides">
            <DashboardPesticides sites={paginatedSites?.results.features ?? []} isLoadingSites={isLoadingSites} />
          </TabsContent>
        </Tabs>
      )
    }

    if (hasBiodiversityAccess) {
      return (
        <DashboardBiodiversity
          account={account}
          stats={stats}
          isLoadingStats={isLoadingStats}
          isLoadingAccount={isLoadingAccount}
        />
      )
    }

    if (hasPesticidesAccess) {
      return <DashboardPesticides sites={paginatedSites?.results.features ?? []} isLoadingSites={isLoadingSites} />
    }

    return null
  }

  return (
    <div className="container">
      <BreadcrumbComponent
        breadcrumbItems={[
          {
            label: t(i18nKeys.beeoimpact.dashboard.breadcrumb.beeoapps),
            href: '/',
          },
          {
            label: t(i18nKeys.beeoimpact.dashboard.breadcrumb.beeoimpact),
          },
        ]}
        currentPage={t(i18nKeys.beeoimpact.dashboard.breadcrumb.dashboard)}
      />
      <div className="flex-1 space-y-4 pt-6">
        <div className="flex items-center justify-between space-y-2">
          <PageHeader>
            <PageHeaderHeading>{t(i18nKeys.beeoimpact.dashboard.title.main)}</PageHeaderHeading>
          </PageHeader>
          <div className="flex items-center space-x-2">
            <Select>
              <SelectTrigger className="w-[180px]">
                <SelectValue defaultValue="2023" placeholder="2023" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="2023">2023</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>

        {renderDashboard()}
      </div>
    </div>
  )
}

export default DashboardImpactPage
