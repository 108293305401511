import React, { useEffect, useState } from 'react'
import { getNaceCodesListQueryKey, naceCodesList } from '@/client/backend/api/nace-codes/nace-codes'
import {
  beeomonitoringSitesSiteSearchRetrieve,
  getBeeomonitoringSitesSiteSearchRetrieveQueryKey,
  sitesCreate,
  sitesUpdate,
} from '@/client/backend/api/sites/sites'
import { BuildingTypeEnum, Site, SiteCreate } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { CheckIcon, ChevronsUpDown, Loader, Loader2 } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Circle, MapContainer, TileLayer, useMap } from 'react-leaflet'

import { DEFAULT_CENTER } from '@/lib/geo'
import { SiteFormValues, siteSchema } from '@/lib/validation/add-site-schema'
import { useAuth } from '@/hooks/useAuth'
import { Button } from '@/components/ui/button'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { SheetFooter } from '@/components/ui/sheet'
import { toast } from '@/components/ui/use-toast'
import { MultiSelectNaceCode } from '@/components/multi-select-nace-code'
import RenderTooltip from '@/components/render-tooltip/render-tooltip'

type SiteFormProps = {
  setOpenSiteSheet?: React.Dispatch<React.SetStateAction<boolean>>
  setSites: React.Dispatch<React.SetStateAction<Site[]>>
  sites: Site[]
  site?: Site
  accountId?: number
  setOpenBomSheet?: React.Dispatch<React.SetStateAction<boolean>>
  formType?: 'bom' | 'site'
}

const SiteForm = ({
  sites,
  site,
  setOpenSiteSheet,
  setSites,
  setOpenBomSheet,
  accountId,

  formType,
}: SiteFormProps) => {
  const { user } = useAuth()
  const [openPop, setOpenPop] = useState(false)
  const [searchKey, setSearchKey] = useState<string>('')
  const [inputKey, setInputKey] = useState<string>('')
  const { t } = useTranslation()
  const [searchNaceCode, setSearchNaceCode] = useState<string>('')

  const center = (site?.properties?.center?.coordinates as number[]) || [0, 0]

  const { data: naceCodes } = useQuery({
    queryKey: [getNaceCodesListQueryKey({ search: searchNaceCode })],
    queryFn: () => naceCodesList({ search: searchNaceCode }),
    enabled: !!searchNaceCode,
  })

  const form = useForm<SiteFormValues>({
    resolver: zodResolver(siteSchema),
    defaultValues: {
      name: site?.properties?.name || '',
      lat: center[1] || 0,
      lng: center[0] || 0,
      surface: site?.properties?.surface_ha || 0,
      radius: site?.properties?.biodiversity_radius || 1500,
      type: site?.properties?.building_type || BuildingTypeEnum.INDUSTRIAL,
      nace_codes: site?.properties?.nace_codes?.map((nace) => nace.code) || [],
      key: '',
    },
    shouldUnregister: false,
    mode: 'onSubmit',
  })

  const { data: beeomonitoringSite } = useQuery({
    queryKey: [getBeeomonitoringSitesSiteSearchRetrieveQueryKey(), searchKey],
    queryFn: () => beeomonitoringSitesSiteSearchRetrieve({ key: searchKey }),
    enabled: !!searchKey,
  })

  const [coordinates, setCoordinates] = useState(DEFAULT_CENTER)
  const latitude = form.watch('lat')
  const longitude = form.watch('lng')

  const [formPending, setFormPending] = useState(false)

  useEffect(() => {
    if (latitude && longitude && !isNaN(latitude) && !isNaN(longitude)) {
      setCoordinates({ lat: latitude, lng: longitude })
    }
  }, [latitude, longitude])

  const handleInputChange = (value: string) => {
    setInputKey(value) // Display value
    setSearchKey(value) // Trigger query only when typing
  }

  // Handle selecting a key from the combobox
  const handleSelect = (selectedKey: string) => {
    const selectedSite = Array.isArray(beeomonitoringSite)
      ? beeomonitoringSite.find((s) => s.key === selectedKey)
      : beeomonitoringSite?.key === selectedKey
        ? beeomonitoringSite
        : null

    if (selectedSite) {
      // Update form values without affecting `searchKey`
      form.setValue('key', selectedSite.key)
      form.setValue('name', selectedSite.name || '')
      form.setValue('lat', selectedSite.latitude || 0)
      form.setValue('lng', selectedSite.longitude || 0)
      setInputKey(selectedSite.key) // Show selected key without triggering again API
    }
    setOpenPop(false) // Close the dropdown
  }

  if (!user) return <Loader />

  const radiusMapping: Record<BuildingTypeEnum, number> = {
    [BuildingTypeEnum.INDUSTRIAL]: 1500,
    [BuildingTypeEnum.OFFICE]: 100,
    [BuildingTypeEnum.AGRICULTURAL]: 1000,
    [BuildingTypeEnum.EXTRACTION]: 10000,
    [BuildingTypeEnum.MANUFACTURING]: 5000,
  }

  const onChangeType = (selectedType: BuildingTypeEnum) => {
    const updatedRadius = radiusMapping[selectedType]
    form.setValue('radius', updatedRadius)
  }

  const onSubmit = async (data: SiteFormValues) => {
    const dataObject = {
      name: data.name,
      lat: data.lat,
      lng: data.lng,
      user_id: user.id.toString(),
      radius: data.radius,
      account_id: accountId ? accountId.toString() : user.account_id.toString(),
      building_type: data.type,
      surface_ha: data.surface,
      nace_codes: data.nace_codes,
      key: data.key,
    } as SiteCreate

    try {
      setFormPending(true)
      if (site) {
        if (!site.id) return
        const updatedSite = await sitesUpdate(site.id.toString(), {
          ...dataObject,
          id: site.id.toString(),
        })

        setSites((prevSites) => {
          return prevSites.map((s) => (s.id === site.id ? updatedSite : s))
        })
        toast({
          title: t(i18nKeys.global.settings.page.sites.sheet.edit.toast.success),
        })
      } else {
        if (formType === 'bom') {
          dataObject.is_in_impact = false
          dataObject.is_in_monitoring = true
        }
        const createdSite = await sitesCreate(dataObject)
        setSites([...sites, createdSite])
        toast({
          title: t(i18nKeys.global.settings.page.sites.sheet.add.toast.success),
        })
      }
      setOpenSiteSheet?.(false)
      setOpenBomSheet?.(false)
    } catch (error) {
      if (isAxiosError(error) && error.response?.data) {
        toast({
          title: site
            ? t(i18nKeys.global.settings.page.sites.sheet.edit.toast.error)
            : t(i18nKeys.global.settings.page.sites.sheet.add.toast.error),
          description: error.response.data[0],
        })
      }
    } finally {
      setFormPending(false)
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        {formType === 'bom' && (
          <FormField
            control={form.control}
            name="key"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t(i18nKeys.global.settings.page.sites.label.key)}</FormLabel>
                <FormControl>
                  <div className="flex flex-col">
                    <Popover open={openPop} onOpenChange={setOpenPop} modal={true}>
                      <PopoverTrigger asChild>
                        <Button variant="outline" role="combobox" aria-expanded={openPop} className="justify-between">
                          {field.value
                            ? Array.isArray(beeomonitoringSite)
                              ? beeomonitoringSite.find((site) => site.key === field.value)?.key
                              : beeomonitoringSite?.key
                            : t(i18nKeys.global.settings.page.sites.label.selectKey)}
                          <ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="p-0">
                        <Command>
                          <CommandInput
                            placeholder={t(i18nKeys.global.settings.page.sites.label.name)}
                            className="h-9"
                            value={inputKey}
                            onValueChange={handleInputChange}
                          />
                          <CommandList>
                            <CommandEmpty>{t(i18nKeys.global.settings.page.sites.label.noKeyFound)}</CommandEmpty>
                            <CommandGroup>
                              {(Array.isArray(beeomonitoringSite) ? beeomonitoringSite : [beeomonitoringSite]).map(
                                (site) => (
                                  <CommandItem
                                    key={site?.key}
                                    value={site?.key}
                                    onSelect={(currentValue) => {
                                      handleSelect(currentValue)
                                    }}
                                  >
                                    <span className="flex w-full items-center justify-between">
                                      <span>{site?.key}</span>
                                      <span className="text-sm text-gray-500">{site?.name}</span>
                                    </span>
                                    <CheckIcon
                                      className={`ml-auto size-4 ${field.value === site?.key ? 'opacity-100' : 'opacity-0'}`}
                                    />
                                  </CommandItem>
                                )
                              )}
                            </CommandGroup>
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel> {t(i18nKeys.global.settings.page.sites.label.name)}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="lat"
          render={({ field }) => (
            <FormItem>
              <FormLabel> {t(i18nKeys.global.settings.page.sites.label.lat)}</FormLabel>
              <FormControl>
                <Input {...field} type="number" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="lng"
          render={({ field }) => (
            <FormItem>
              <FormLabel> {t(i18nKeys.global.settings.page.sites.label.lng)}</FormLabel>
              <FormControl>
                <Input {...field} type="number" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <MapContainer center={[coordinates.lat, coordinates.lng]} zoom={13} zoomControl={false} className="h-24 w-full">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <MapComponent coordinates={coordinates} />
        </MapContainer>
        <FormField
          control={form.control}
          name="surface"
          render={({ field }) => (
            <FormItem>
              <FormLabel> {t(i18nKeys.global.settings.page.sites.label.surface)}</FormLabel>
              <FormControl>
                <Input {...field} type="number" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="nace_codes"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>{t(i18nKeys.global.settings.page.sites.label.nace_code)}</FormLabel>
                <MultiSelectNaceCode
                  selected={field.value ?? []}
                  searchInput={searchNaceCode}
                  setSearchInput={(value) => setSearchNaceCode(value)}
                  options={naceCodes?.map((code) => ({
                    value: code.code,
                    label: `${code.code} - ${code.name}`,
                  }))}
                  {...field}
                  className="h-56 sm:w-[315px]"
                  emptyCommand={t(i18nKeys.global.settings.page.multiSelect.noNaceCode)}
                />
                <FormMessage />
              </FormItem>
            )
          }}
        />

        <FormField
          control={form.control}
          name="type"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t(i18nKeys.global.settings.page.sites.label.type)}</FormLabel>
              <Select
                onValueChange={(e) => {
                  field.onChange(e)
                  onChangeType(e as BuildingTypeEnum)
                }}
                defaultValue={field.value}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {Object.entries(BuildingTypeEnum).map(([key, value]) => {
                    return (
                      <SelectItem key={key} value={value}>
                        {t(i18nKeys.global.settings.page.sites.buildingType[key])}
                      </SelectItem>
                    )
                  })}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="radius"
          render={({ field }) => (
            <FormItem>
              <div className="flex items-center space-x-2">
                <FormLabel> {t(i18nKeys.global.settings.page.sites.label.radius)} (m)</FormLabel>
                <RenderTooltip explanationText={t(i18nKeys.global.settings.page.sites.radius.definition)} size={20} />
              </div>
              <FormControl>
                <Input type="number" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <SheetFooter>
          <Button type="submit" disabled={formPending}>
            {formPending && <Loader2 className="mr-2 size-4 animate-spin" />}
            {site ? t(i18nKeys.global.common.buttons.editSite) : t(i18nKeys.global.common.buttons.addSite)}
          </Button>
        </SheetFooter>
      </form>
    </Form>
  )
}

const MapComponent = ({ coordinates }) => {
  const map = useMap()

  useEffect(() => {
    if (coordinates.lat && coordinates.lng) {
      map.flyTo([coordinates.lat, coordinates.lng])
    }
  }, [coordinates, map])

  return <Circle center={[coordinates.lat, coordinates.lng]} radius={50} color={'cadetblue'} fillColor="cadetblue" />
}

export default SiteForm
