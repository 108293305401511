import { Site, User } from '@/client/backend/models'

import { FeatureType } from '@/types/feature-type'
import { ScoringType } from '@/types/scoring-type'
import { MODULE } from '@/lib/module'
import { hasAccesTo } from '@/lib/permission'
import SitesMapImpact from '@/components/beeoimpact/sites-map-impact'
import { default as SitesMap } from '@/components/sites/sites-map'

import { LightSites } from '../../types/light-site'

interface SitesMapContainerProps {
  module: MODULE
  sites: Site[]
  hoveredSiteId?: number
  hoveredCardRef: React.RefObject<number | null>
  isLoading: boolean
  scoringType: ScoringType
  user: User
}

export const SitesMapContainer = ({
  module,
  sites,
  hoveredSiteId,
  hoveredCardRef,
  isLoading,
  scoringType,
  user,
}: SitesMapContainerProps) => {
  const lightSites = transformToLightSites(sites, user)
  return (
    <div className="sticky top-16 h-[calc(100vh-10px)] md:w-0 lg:w-[400px] xl:w-[504.1px] 2xl:w-[504.1px] ">
      {module === MODULE.IMPACT ? (
        <SitesMap
          sites={sites}
          hoveredCard={hoveredSiteId}
          hoveredCardRef={hoveredCardRef}
          isLoading={isLoading}
          module={module}
          scoringType={scoringType}
        />
      ) : module === MODULE.INITIATIVE ? (
        <SitesMap
          sites={lightSites ?? []}
          hoveredCard={hoveredSiteId}
          hoveredCardRef={hoveredCardRef}
          isLoading={isLoading}
          module={module}
          scoringType={scoringType}
        />
      ) : (
        <SitesMapImpact sites={lightSites ?? []} hoveredSiteId={hoveredSiteId} showTooltip={false} />
      )}
    </div>
  )
}

const transformToLightSites = (sites: Site[], user: User | undefined): LightSites[] => {
  return sites.map((site): LightSites => {
    const lightSite: LightSites = {
      id: site.id ?? 0,
      name: site.properties?.name ?? '',
      center: site.properties?.center ?? { type: 'Point', coordinates: [0, 0] },
    }

    if (hasAccesTo(user?.features ?? [], FeatureType.PESTICIDES) && site.properties?.stats_pesticides) {
      lightSite.score_pesticides = site.properties?.stats_pesticides?.ecoscore
    }
    if (hasAccesTo(user?.features ?? [], FeatureType.BIODIVERSITY) && site.properties?.stats_biodiversity) {
      lightSite.score_biodiversity = site.properties?.stats_biodiversity?.biodiversity_score
    }

    return lightSite
  })
}
